body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Urbanist'
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.spinner-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-spinner {
  width: 60px;
  aspect-ratio: 1;
  color: #0a6b6b;
  border-radius: 50%;
  display: grid;
  background: 
    conic-gradient(from 90deg at 4px 4px,#0000 90deg,currentColor 0)
    -4px -4px/calc(50% + 2px) calc(50% + 2px),
    radial-gradient(farthest-side,currentColor 6px,#0000 7px calc(100% - 6px),currentColor calc(100% - 5px)) no-repeat;
  animation: l10 2s infinite linear;
}
.loading-spinner:before {
  content: "";
  border-radius: inherit;
  background: inherit;
  transform: rotate(45deg);
}
@keyframes l10 {to{transform: rotate(.5turn)}}

.datePicker {
  width: 100%;
  display: inline-block;
  font-family: inherit;
  position: relative;
  z-index: 9999;
}

.datePicker input {
  width: 100%;
}
