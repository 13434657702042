@media screen and (max-width: 800px){
   
    .login-form-content {
        width: 340px !important;
    }
    
    .login-title {
        font-size: var(--login-title-font-size) !important;
    }
    
    .form-label {
        font-size: var(--form-label-font-size) !important;
    }

    .forget-passowrd-text {
        font-size: var(--form-forget-font-size) !important;
    }

    .btn-login-page {
        font-size: var(--global-mobile-font-size-title) !important;
    }

    .login-button-div {
        padding-top: 5% !important;
    }

    .create-acount {
        font-size: var(--form-forget-font-size) !important;
    }

    .btn-social-login {
        font-size: var(--global-mobile-font-size-title) !important;
    }

    .login-btn-facebook {
        width: 200px !important;
    }
    
    .login-btn-google {
        width: 100% !important;
    }

    .login-case {
        width: 350px !important;
    }

}

.login-content {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fefefe;
}

.login-form-content {
    background-color: var(--content-box-color);
    border-radius: 6px;
}

.or-div {
    padding-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: var(--global-font-size);
}

.or-content-box {
    background-color: white;
    height: 30px;
    width: 30px;
    min-height: 30px;
    min-width: 30px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.social-login-div {
    padding-top: 15px;
    display: flex;
    justify-content: center;
}

.spacer-login-div{
    width: 10%;
}

.login-btn-facebook {
    width: 120px;
    background-color: blue;
    border: 1px solid darkblue;
}

.login-btn-google {
    width: 100% !important;
    background-color: red;
    border: 1px solid darkred;
}

.ou-bar-div {
    height: 30px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ou-bar {
    background-color: gray;
    height: 1px;
    width: 80%;
}

.logo {
    height: 120px;
    width: 200px;
}

.login-button-div {
    padding-top: 20px;
}