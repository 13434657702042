/*.hidden{display:none !important}*/

.banner {
    background-color: var(--amplify-components-button-menu-hover-background-color);
    height: 150px;
}

.logo {
    width: 40%;
    padding: 10px;
    height: fill;
    display: flex;
    align-items: center;
    justify-content: center;
}

.textcontainer {
    height: 60%;
    width: fill;
    display: flex;
    font-weight: bold;
}

.textdiv {
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: fill;
}

.buttondiv {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40%;
    padding-left: 10%;
}

.buttonNow {
    height: 40px;
    width: 80px;
    border-radius: 20px;
    background-color: #fff;
    color:  var(--amplify-components-button-menu-hover-background-color);
}

.buttonLatter {
    height: 40px;
    width: 120px;
    opacity: 80%;
    color: white;
}

.buttonLatter:hover {
    background-color: transparent;
    color: white;
    opacity: 100%;
}