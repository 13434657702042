.modal-dialog::backdrop {
  background: hsl(0 0% 0% / 50%);
}

.modal-dialog {
  max-width: 20rem;
  padding: 2rem;
  border: 0;
  border-radius: 0.5rem;
  position: relative;
  border-radius: 1rem;
  position: fixed;
  box-shadow: hsl(0 0% 0% / 10%) 0 0 0.5rem 0.25rem;
  border: 1px solid #ccc;
}

.modal-close-btn {
  cursor: pointer;
  font-size: 1.8em;
  font-weight: 600;
  position: absolute;
  top: 0.05em;
  right: 0.25em;

  &:hover, &:active {
    color: #f69697;
    text-shadow: 3px 4px 7px rgba(81,67,21,0.8);
  }
}